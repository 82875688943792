
function changeLanguage(event: kendo.ui.DropDownListSelectEvent) {

    var ddvalue = event.sender.dataItem(event.item).value;
    $("#languageSelection").text(kendo.format(languageDropDownTitleForAria + ": {0}", event.sender.dataItem(event.item).text));
    $.ajax({
        type: "GET",
        url: '/Language/ChangeLanguageClient2/?lang=' + ddvalue,
        success: handleResponse,
        error: (error: any) => handleError(error)
    });
};

function handleResponse(response: any) {
    location.reload();
}

function handleKendoDataSourceError(errorEvent: kendo.data.DataSourceErrorEvent) {
    handleError(errorEvent.xhr);
}

function handleError(xhr: any) {
    var responseJSON = xhr.responseJSON;
    showKendoErrorDialog(
        responseJSON.errorType,
        responseJSON.errorMsg,
        false);
}

function setSelected(e: kendo.ui.DropDownListDataBoundEvent) {
    this.select((dataItem: any) => dataItem.selected === true);
    $("#languageDD").attr("title", `${ languageDropDownTitleForAria } : ${e.sender.text()}`);
};